import React from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  ContainerCustom,
  Content,
  H2,
  Medailon,
  PaddingWrapper,
} from "@igloonet-web/shared-ui"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import OgImage from "../../../images/reference/ccrjm/ogimage.png"
import Predstaveni from "../../../images/reference/ccrjm/predstaveni.png"
import Predstaveni2 from "../../../images/reference/ccrjm/predstaveni2.png"
import UkazkaKodu from "../../../images/reference/ccrjm/kod.png"
import QRUkazka from "../../../images/reference/ccrjm/qrko.png"
import Vyhody from "../../../images/reference/ccrjm/vyhody.png"
import Logo from "../../../images/reference/ccrjm/logo-convention.png"
import Monitor from "../../../images/reference/ccrjm/monitor.png"
import Sobol from "../../../images/team/mini/sobol.jpg"
import Program from "../../../images/reference/ccrjm/program.png"
import styled from "styled-components"
import NegativeMarginWrapper from "@igloonet-web/shared-ui/components/negative-margin-wrapper"

const Img = styled.img`
  display: flex;
  margin: 0 auto;
  text-align: center;
  max-width: 100%;
  height: auto;

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    margin: 5rem auto;
  }
`

const CCRJM: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          Vytvořili jsme eventovou aplikaci pro Brno Convention Bureau |
          igloonet.cz
        </title>
        <meta
          name="description"
          content="Podívejte se, jak jsme rozlouskli import dat z několika různých zdrojů, napojení na mapy nebo měření embedovaného kódu."
        />
        <meta
          property="og:title"
          content="Vytvořili jsme eventovou aplikaci pro Brno Convention Bureau | igloonet.cz"
        />
        <meta property="og:image" content={OgImage} />
      </Helmet>

      <ReferenceHeader
        companyName={`Centrála cestovního ruchu<br>&nbsp;-&nbsp;Jižní Morava`}
        heading="Vytvořili jsme eventovou aplikaci pro Brno Convention Bureau"
        date="červenec 2022 - říjen 2022"
      />

      <ReferenceImageText
        right
        img={Monitor}
        eager
        alt=""
        logo={Logo}
        logoAlt="logo Brno Convention Bureau"
        link="//brnoconvention.cz/"
      >
        <p>
          V&nbsp;Brně se pořád něco děje! V&nbsp;Centrále cestovního ruchu proto
          chtěli pořadatelům nabídnout pomocnou ruku v&nbsp;tom, jak dát
          o&nbsp;akcích jednoduše a&nbsp;on‑line (bez letáků!) vědět. Ať mají na
          jednom místě jak program a&nbsp;podrobnější info, tak třeba to, jak se
          na jejich událost dostat, kde se v&nbsp;okolí ubytovat nebo dobře
          najíst.
        </p>
        <p>
          Dali jsme hlavy dohromady a&nbsp;vytvořili eventovou aplikaci.
          Podívejte se, jak jsme rozlouskli import dat z&nbsp;několika různých
          zdrojů, napojení na mapy nebo měření vloženého kódu.&#128071;
        </p>
      </ReferenceImageText>

      <PaddingWrapper>
        <ReferenceImageText
          halfImgSize
          responsiveTextOrder
          img={Predstaveni}
          alt=""
        >
          <H2>CCRJM a projekt Brno Convention Bureau</H2>
          <p>
            Garantem projektu Brno Convention Bureau (BCB), pod který aplikace
            spadá, je Centrála cestovního ruchu&nbsp;–&nbsp;Jižní Morava
            (CCRJM). Zabývá se rozvojem cestovního ruchu a&nbsp;turistického
            potenciálu v&nbsp;kraji.
          </p>
          <p>
            Práce s&nbsp;aplikací je pro organizátory velmi snadná. Stačí zadat
            program, termín a&nbsp;místo akce, popř. vložit dokumenty ke
            stažení. Vše ostatní už nabídne appka sama&nbsp;–&nbsp;navrhne
            dopravní trasy, doporučí ubytování a&nbsp;gastro v&nbsp;okolí
            a&nbsp;k&nbsp;tomu přidá inspiraci na další zážitky. Pořadatel si
            pak může vygenerovanou stránku, anebo jen její část, spolu s&nbsp;QR
            kódem ke sdílení vložit na web. A&nbsp;to zcela zdarma.
          </p>
        </ReferenceImageText>
      </PaddingWrapper>

      <ContainerCustom>
        <p className="w-lg-50 text-center mx-auto mb-3">
          Přínosy aplikace si můžeme rovnou vypůjčit z webu projektu BCB:
        </p>
        <Img src={Vyhody} alt="" width={1200} height={500} loading="lazy" />
      </ContainerCustom>

      <ReferenceImageText>
        <H2>Jak jsme postupovali</H2>
        <p>
          Díky tomu, že měl zadavatel jasno v&nbsp;tom, co potřebuje, vznikla na
          společných schůzkách s&nbsp;UX designerem a&nbsp;grafikem konkrétní
          podoba aplikace. Na nás pak bylo návrh celkově oživit a&nbsp;vytvořit
          nadesignované funkce.
        </p>
      </ReferenceImageText>

      <PaddingWrapper>
        <Medailon
          personName="Jan Nobilis"
          position={`vedoucí vývoje<br/>a jednatel`}
          img={Sobol}
        >
          <p>
            Práce na projektu byla pro mě radost. Ne vždy se totiž sejde tým
            lidí, kde mají všichni členové dostatečný prostor zaměřit se na
            jednu konkrétní činnost a&nbsp;jsou motivováni tím nejlepším
            výsledkem.
          </p>
        </Medailon>
      </PaddingWrapper>

      <NegativeMarginWrapper>
        <ContainerCustom>
          <Content>
            <ol>
              <li>
                <strong>Import bodů zájmů z&nbsp;cizích webů</strong>
                <p>
                  Aby se v&nbsp;administraci nemusela duplikovat správa bodů
                  zájmů (aka{" "}
                  <a
                    href="//en.wikipedia.org/wiki/Point_of_interest"
                    target="_blank"
                    rel="noreferrer"
                  >
                    points of interest
                  </a>{" "}
                  , tedy zajímavých či užitečných míst na mapě), importujeme
                  data z&nbsp;několika dalších webů spojených
                  s&nbsp;jihomoravským cestovním ruchem:{" "}
                  <a href="//gotobrno.cz/" target="_blank" rel="noreferrer">
                    gotobrno.cz
                  </a>
                  ,{" "}
                  <a
                    href="//brnoconvention.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    brnoconvention.com
                  </a>{" "}
                  či{" "}
                  <a
                    href="//gourmetjiznimorava.cz/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    gourmetjiznimorava.cz
                  </a>
                  .
                </p>
                <p>
                  Načítáme tedy informace hned ze 3&nbsp;různých zdrojů, ve
                  3&nbsp;různých formátech a&nbsp;ve 3&nbsp;různých jazycích.
                  Importy můžeme provádět opakovaně buď v&nbsp;předem daných
                  intervalech, anebo je spustit ručně z&nbsp;administrace
                  aplikace.
                </p>
                <p>
                  Body zájmu doplňujeme automaticky informacemi, které
                  potřebujeme pro naši aplikaci. Např. zařazení do správné
                  kategorie, stažení obrázků či doplnění geolokačních souřadnic
                  nezbytných pro zobrazení v&nbsp;mapě.
                </p>
                <Img
                  src={UkazkaKodu}
                  alt=""
                  width={411}
                  height={485}
                  loading="lazy"
                />
              </li>
              <li>
                <strong>Výběr mapových služeb</strong>
                <p>
                  Při výběru poskytovatele mapových služeb jsme zvažovali dvě
                  možnosti. Český Mapotic, který s&nbsp;sebou ale nesl
                  komplikovanější řešení dalších exportů&nbsp;/&nbsp;importů
                  bodů zájmu, a&nbsp;Google Maps Platform, umožňující snazší
                  získávání geo souřadnic na základě dotazů na adresu nebo název
                  podniku. Zvolili jsme proto propojení přes Google Maps API.
                </p>
                <Img
                  src={Predstaveni2}
                  alt=""
                  width={308}
                  height={558}
                  loading="lazy"
                />
              </li>
              <li>
                <strong>
                  Sdílení obsahu a&nbsp;měření přístupů na backendu
                </strong>
                <p>
                  Jedním z&nbsp;požadavků v&nbsp;zadání projektu byla možnost
                  sdílení stránek nebo jen určitých informací dané události.
                  Kromě klasického šíření přes odkaz jsme proto pro pořadatele
                  připravili také QR kódy a&nbsp;funkci „embedování“. Můžou si
                  tak celou stránku své události, nebo jen její libovolnou část,
                  vložit pomocí vygenerovaného kódu na vlastní web. Velmi snadno
                  a&nbsp;zdarma tak návštěvníkům nabídnou další servis.
                </p>
                <Img
                  src={QRUkazka}
                  alt=""
                  width={308}
                  height={558}
                  loading="lazy"
                />
                <p>
                  Jak ale měřit návštěvnost nebo počet zobrazení stránek, když
                  přirozeně nemáte k&nbsp;cizím webům a&nbsp;k&nbsp;jejich
                  analytice přístup? O&nbsp;taková data přece nechcete přijít.
                  My je umíme posílat přes backend do Google Analytics pomocí
                  Measurement Protocolu.
                </p>
              </li>
              <li>
                <strong>
                  Propojení webové aplikace a&nbsp;osobního kalendáře
                </strong>
                <p>
                  Pro návštěvníky používající Google kalendář (nebo jakýkoliv
                  jiný kalendář, co umí zpracovat .ics soubory) jsme vytvořili
                  generátor. Informace o&nbsp;události si tak můžou jednoduše do
                  svého kalendáře nahrát a&nbsp;mít je vždy po ruce.
                </p>
                <Img
                  src={Program}
                  alt=""
                  width={756}
                  height={200}
                  loading="lazy"
                />
              </li>
            </ol>
          </Content>
          <PaddingWrapper>
            <Medailon
              personName="Jan Nobilis"
              position={`vedoucí vývoje<br/>a jednatel`}
              img={Sobol}
            >
              <p>
                Po technické stránce se jednalo o&nbsp;zajímavý projekt.
                Propojili jsme redakční systém Drupal s&nbsp;Google Maps API.
                Pomocí geocoder rozšíření načítáme GPS souřadnice k&nbsp;obsahu
                a&nbsp;díky kontextuálním filtrům v pohledech hledáme obsah se
                souřadnicemi v&nbsp;konkrétním rádiu.
              </p>
            </Medailon>
          </PaddingWrapper>
        </ContainerCustom>
      </NegativeMarginWrapper>

      <ContactForm contact="sobol" />
    </Layout>
  )
}

export default CCRJM
