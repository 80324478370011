//TODO ZJEDNODUŠIT
import React from "react"
import { Row, Col, Container } from "reactstrap"
import styled from "styled-components"
import classnames from "classnames"
import LogoWrapper from "./logo-wrapper"

type Props = {
  className?: string
  halfImgSize?: boolean
  right?: boolean
  responsiveTextOrder?: boolean
  children?: React.ReactNode
  img?: string
  alt?: string
  logo?: string
  logoAlt?: string
  link?: string
  width?: string
  height?: string
  svgImage?: boolean
  title?: string
  video?: string
  poster?: string
  eager?: boolean
}

const Wrapper = styled(Container)`
  position: relative;
`

const WrapperImg = styled(Col)`
  width: 90%;
  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    width: 45%;
  }
`

const Img = styled.img`
  width: 100%;
  height: auto;

  &.svgImage {
    width: 200px;
    @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
      width: 300px;
    }
  }
`

const WrapperText = styled.div`
  ul {
    margin: 0 0 15px;

    > li {
      margin: 0;
      list-style-type: none;
      text-indent: -1.5rem;

      &:before {
        content: "";
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        margin-right: 1rem;
        background: ${(props) => props.theme.color.primary200};
        font-weight: 700;
        text-align: right;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
    width: 75%;
  }
`

const ReferenceImageText: React.FC<Props> = ({
  img,
  alt,
  halfImgSize,
  right,
  responsiveTextOrder,
  children,
  logo,
  logoAlt,
  link,
  width,
  height,
  svgImage,
  title,
  video,
  eager,
}) => {
  return (
    <Wrapper fluid className="py-lg-4">
      <Row className="justify-content-center">
        {img && (
          <WrapperImg
            md={7}
            lg={6}
            xl={halfImgSize ? 5 : 6}
            xxl={halfImgSize ? 4 : 6}
            className={classnames(
              "p-0 d-flex",
              { "px-lg-5 mx-lg-5": halfImgSize },
              {
                "order-last": right,
              },
              {
                "order-last order-lg-first": responsiveTextOrder,
              }
            )}
          >
            <div className="m-auto">
              <Img
                src={img}
                alt={alt}
                loading={eager ? "eager" : "lazy"}
                width={width}
                height={height}
                className={svgImage ? "svgImage" : ""}
              />
              {title && <p className="text-center">{title}</p>}
            </div>
          </WrapperImg>
        )}

        {video && (
          <WrapperImg
            md={7}
            lg={6}
            xl={halfImgSize ? 5 : 6}
            xxl={halfImgSize ? 4 : 6}
            className={classnames(
              "p-0 d-flex embed-responsive embed-responsive-16by9",
              { "px-lg-5 mx-lg-5": halfImgSize },
              {
                "order-last": right,
              },
              {
                "order-last order-lg-first": responsiveTextOrder,
              }
            )}
          >
            <div className="m-auto">
              <div>
                <iframe
                  className="embed-responsive-item"
                  src={video}
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </WrapperImg>
        )}

        {children && (
          <Col className="d-flex flex-column" md={7} lg={5} xl={6}>
            <WrapperText className="m-auto px-2 py-5">{children}</WrapperText>
          </Col>
        )}

        {logo && <LogoWrapper logo={logo} logoAlt={logoAlt} link={link} />}
      </Row>
    </Wrapper>
  )
}

export default ReferenceImageText
