//TODO SJEDNOTIT A POUŽÍT ZDE H1 NADPIS?
import React from "react"
import styled from "styled-components"
import { ContainerCustom } from "@igloonet-web/shared-ui"
import Floe from "../../images/reference/reference_floe.svg"

type Props = {
  companyName: string
  heading: string
  date: string
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    margin-bottom: -50%;

    &:after {
      display: block;
      content: "";
      background: url(${Floe}) no-repeat bottom left;
      background-size: contain;
      width: 100%;
      padding-top: 55.84%;
      z-index: -1;
    }
  }
`

const HeadingWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    width: 90%;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
    width: 80%;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.xxl}) {
    width: 70%;
  }

  > :first-child {
    color: ${(props) => props.theme.color.primary};
    text-transform: uppercase;
  }

  > :nth-child(2) {
    @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
      max-width: 75%;
    }
  }
`

const DateWrapper = styled.div`
  display: inline-block;
  padding: 3px 18px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  min-width: 87px;
  background: ${(props) => props.theme.color.beige300};
  color: ${(props) => props.theme.color.gray900};
`

const H1Custom = styled.h1`
  font-size: 36px;
`

const ReferenceHeader: React.FC<Props> = ({ companyName, heading, date }) => {
  return (
    <div>
      <Wrapper>
        <ContainerCustom className="pt-5">
          <HeadingWrapper>
            <H1Custom
              className="mb-0 text-left"
              dangerouslySetInnerHTML={{ __html: companyName }}
            />
            <h1
              className="text-left d-block mb-2"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
          </HeadingWrapper>
          <DateWrapper className="my-3">{date}</DateWrapper>
        </ContainerCustom>
      </Wrapper>
    </div>
  )
}

export default ReferenceHeader
