import React from "react"
import styled from "styled-components"

type Props = {
  logo?: string
  logoAlt?: string
  link?: string
}

const Wrapper = styled.div`
  position: relative;
  visibility: hidden;
  width: 1px;
  height: 1px;

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    visibility: visible;
    position: absolute;
    bottom: -18%;
    left: 0;
    width: 18%;
    height: 8rem;
    background: ${(props) => props.theme.color.white};
    z-index: 2;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:active {
      width: 23%;
      transition: all 0.5s ease-in-out;
    }
  }
`

const ImgWrapper = styled.div`
  margin: auto;
  max-width: 200px;

  img {
    max-height: 6.75rem;
    max-width: 95%;
    width: auto;
  }
`

const LogoWrapper: React.FC<Props> = ({ logo, logoAlt, link }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <Wrapper className="d-lg-flex justify-content-end">
        <ImgWrapper>
          <img
            src={logo}
            alt={logoAlt}
            loading="lazy"
            width={"100%"}
            height={"100%"}
          />
        </ImgWrapper>
      </Wrapper>
    </a>
  )
}

export default LogoWrapper
