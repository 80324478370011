import React from "react"
import styled from "styled-components"

type Props = {
  children: React.ReactNode
}

const Wrapper = styled.div`
  margin-top: -4rem;
`

const NegativeMarginWrapper: React.FC<Props> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}
export default NegativeMarginWrapper
